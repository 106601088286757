import { BaseApi } from './BaseApi'

/**
 * Create an Account API wrapper around axios.
 */
class AccountApi extends BaseApi {
  constructor () {
    super()
    this.type = 'account'
  }

  /**
   * Create new account
   */
  async createAccount(body) {
    const url = this.baseDomain + `/account-create`
    let idToken = await firebase.auth().currentUser.getIdToken()
    this.api.defaults.headers.common['Authorization'] = idToken
    return this.api.post(url, body)
  }

  updateClientCode(clientCode, accountId) {
    const url = this.baseDomain + `/account/${ accountId }/update-client-code`
    return this.api.post(url, { client_code: clientCode })
  }
}

export default new AccountApi()
